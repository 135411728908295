.documentChecklistWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
}

.checklistWrapper {
    display: flex;
    grid-gap: 16px;
    overflow: auto;
    width: calc(100% + 40px);
    margin-right: -40px;
}

.checklistWrapper > div {
    width: 40%;
    flex-shrink: 0;
}

.checklistWrapper > div:last-child {
    margin-right: 40px;
}