.flex {
    display: flex;
}
.row {
    flex-direction: row;
}
.sBetween {
    justify-content: space-between;
}
.centered {
    align-items: center;
}
.sAround {
    justify-content: space-around;
}
.fEnd {
    align-items: flex-end;
}
.jCenter {
    justify-content: center;
}

.jEnd {
    justify-content: flex-end;
}
