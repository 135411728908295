.wrapper {
    /*border: 1px dashed var(--borderGrey);*/
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D5D5D5FF' stroke-width='1' stroke-dasharray='16%2c 16' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
    height: 248px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
