.root {
  display: inline-flex;
  grid-gap: 0.5rem;
  padding: 0.125rem 0;
  background: 0 0;
  border: 0;
  font-size: 0.75rem;
  color: var(--mint);
  cursor: pointer;
  text-transform: uppercase;
  border-bottom: 1px solid currentColor;
}