.wrapper {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--borderGrey);
    height: max-content;
}

.activeWrapper {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--mint);
    height: max-content;
}
