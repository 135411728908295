.wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--borderGrey);
    border-radius: 8px;
    padding: 24px;
}

.dotsWrapper {
    position: relative;
}

.menuWrapper {
    position: absolute;
    border-radius: 8px;
    width: 188px;
    background-color: var(--white);
    left: -164px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04)
}

.menuItemWrapper:hover {
    border-radius: 8px;
    background-color: var(--borderGrey50);
}
