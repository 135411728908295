.wrapper {
    width: 100%;
    height: 53px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--mint);
    border-radius: 8px;
    transition: .3s;
    cursor: pointer;
}

.wrapper:hover {
    background-color: var(--mint50);
}

.wrapperDelete {
    width: 100%;
    height: 53px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--errorRed);
    border-radius: 8px;
    transition: .3s;
    cursor: pointer;
}

.wrapperDelete:hover {
    background-color: var(--errorRed50);
}

.wrapperBordered {
    width: 100%;
    height: 53px;
    border: 1px solid var(--borderGrey);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border-radius: 8px;
    transition: .3s;
    cursor: pointer;
}

.wrapperBordered:hover {
    border: 1px solid var(--black);
}

.wrapperDisabled {
    width: 100%;
    height: 53px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--mint50);
    border-radius: 8px;
    cursor: not-allowed;
}

.fontStyle {
    font-size: 16px;
    font-weight: 600;
    color: var(--white);
}

.fontStyleBordered {
    font-size: 16px;
    font-weight: 600;
    color: var(--black);
}
