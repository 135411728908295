.inputWrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16px;
  align-items: center;
  width: 100%;
  padding: 24px 0;
  background-color: #FFFFFF;
  border-bottom: 1px solid var(--borderGrey);
}

.inputWrapper svg {
  margin-top: -2px;
}

.input {
  display: block;
  border: 0;
  font-weight: 500;
  font-size: 18px;
  background-color: transparent;
}

.inputLabel {
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 0.75rem;
}

.input::placeholder {
  font-size: 18px;
  color: var(--titleGrey);
}