body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bgColor);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus,
select:focus,
div:focus {
    outline: none;
}
html{
    height: 100%;
}

#root{
    height: 100%
}

:root {
    --bgColor: #204BB905;
    --white: #FFF;
    --mint: #2A9D8F;
    --mint50: #2A9D8F80;
    --borderGrey: #D5D5D5;
    --borderGrey50: #D5D5D580;
    --titleGrey: #737373;
    --black40: #00000066;
    --black: #000000;
    --errorRed: #F04F4F;
    --errorRed50: #F04F4F80;
}
